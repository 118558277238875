// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/black-friday-modal-bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".black-friday-modal {\n  color: #fff;\n}\n.black-friday-modal .modal-dialog {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    max-width: 100%;\n}\n.black-friday-modal .modal-content {\n    width: 100%;\n    max-width: 650px;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.black-friday-modal .modal-body {\n    padding: 30px;\n}\n@media (max-width: 545px) {\n.black-friday-modal .modal-body {\n        padding: 30px 16px;\n}\n}\n.black-friday-modal__close-button {\n    position: absolute;\n    inset: 0 0 auto auto;\n    padding: 16px;\n    margin: 0;\n    line-height: 12px;\n    border: none;\n    background: none;\n}\n.black-friday-modal__title {\n    margin: 0;\n    font-size: 40px;\n    font-weight: 900;\n    line-height: normal;\n    text-align: center;\n}\n@media (max-width: 545px) {\n.black-friday-modal__title {\n        font-size: 32px;\n}\n}\n.black-friday-modal__subtitle {\n    margin: 8px 0 0 0;\n    font-size: 24px;\n    font-weight: 400;\n    line-height: normal;\n    text-align: center;\n    color: #2193b7;\n}\n@media (max-width: 545px) {\n.black-friday-modal__subtitle {\n        font-size: 16px;\n}\n}\n.black-friday-modal__list {\n    padding: 0;\n    margin-top: 16px;\n    list-style: none;\n}\n.black-friday-modal__list-item + .black-friday-modal__list-item {\n    margin-top: 9px;\n}\n", ""]);
// Exports
module.exports = exports;
