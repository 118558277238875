<template>
  <div>
    <!-- Модальное окно черной пятницы: -->
    <BlackFridayModal
      v-if="!showExplanation"
      v-model="showBlackFridayModal"
      @change-tariff="prePayFromBlackfriday"
    />

    <!-- Окно преоплаты: -->
    <ChangeTariff
      v-if="showExplanation"
      :show-explanation="showExplanation"
      :target-tariff="targetTariff"
      @firstBuyTraining="toggleShowCongratulate"
      @updateShow="updateShow"
      @errorPopup="handleError"
      @tariffChanged="showBlackFridayModal = false"
    />

    <!-- Контент страницы: -->
    <PurchaseSuccessfulModal
      :show="showCongratulate"
      @close="showCongratulate = false"
    />

    <div class="header mb-4">
      <div class="d-flex flex-column align-items-start">
        <div class="subscribe__tabs">
          <p
            :class="
              showTab === 0
                ? 'subscribe__tabs__item _active'
                : 'subscribe__tabs__item'
            "
            @click="nextTab(0)"
          >
            {{ $t(`lk.subscribe.tabSubscribe`) }}
          </p>
          <p
            :class="
              showTab === 1
                ? 'subscribe__tabs__item  _active'
                : 'subscribe__tabs__item'
            "
            @click="nextTab(1)"
          >
            {{ $t(`lk.subscribe.tabTraining`) }}
          </p>
        </div>
        <CSpinner v-if="loadingTariffs || loadingEduTariffsAccess" color="primary" size="sm" />
      </div>
      <CRow>
        <CCol col="12">
          <!--          <div v-if="showPayLoader" class="d-flex align-items-center">-->
          <!--            <CSpinner-->
          <!--              color="primary"-->
          <!--              size="sm"-->
          <!--              class="mr-3"-->
          <!--            />-->
          <!--            <h5 class="mb-0">{{ $t('lk.subscribe.waitTransaction') }}</h5>-->
          <!--          </div>-->
        </CCol>
      </CRow>
    </div>
    <div v-if="!loadingTariffs && !loadingEduTariffsAccess" class="subcards">
      <list-training
        :to="'training-detail'"
        v-if="showTab === 1"
        :showCurator="true"
      ></list-training>
      <div
      v-if="showTab === 0">
        <div class="tarff-card-wrapper">
          <div class="tarff-card-wrapper__controls">
            <div class="d-flex align-items-center position-relative tumbler-wrapper">
              <label class="mb-0" @click="isYear = false">
                1&nbsp;{{ $t('general.monthAbbr') }}
              </label>
              <CSwitch
                color="primary"
                shape="pill"
                :checked.sync="isYear"
                class="mx-3 switch-custom tarff-card-wrapper__controls-switch"
                id="switch"
              />
                <label class="mb-0" @click="isYear = true">
                  1&nbsp;{{ $t('general.year') }}
                </label>
                <span
                   v-if="!blackFriday"
                   class="tarff-card-wrapper__discount-pin"
                >
                  -17%
                </span>
                <span
                  v-else
                  class="tarff-card-wrapper__black-friday"
                >
                  Black friday
                </span>
            </div>
            <span
            v-if="userTariffId && userTariffId.includes('trial_')"
            class="tarff-card-wrapper__trial">
              {{ $t('lk.subscribe.actveTrial') }}
              {{ tariffFinishDate }}
            </span>
          </div>
          <TariffCard
          v-for="item in subscribeData"
          :key="item.id"
          :tariff="item"
          :isYear="isYear"
          :to-date="item.isTraining ? null : getToDate"
          :active-tariff-id="getEvents && item.isTraining ? getEvents?.tariff?.id : userTariffId"
          :active-tariff="getEvents && item.isTraining ? getEvents?.tariff?.id == item.id : userTariffActive"
          @pay="prePay"/>
        </div>

        <countdown
          v-if="blackFriday"
          v-show="isYear"
          :time="blackFridayDiff"
          title="Black Friday"
          :subtitle="$t('lk.training.timeLeft')"
        />
        <div class="tariff-desc">
          <div class="tariff-desc__title">
            {{ $t('lk.subscribeConstructor.mainFunctions') }}
          </div>
          <div class="tariff-desc__table-wrapper">
            <table class="table mb-0 tariff-desc__table">
              <thead>
                <tr>
                  <th class="tariff-desc__table-template-lg">
                  </th>
                  <th
                  class="text-center text-uppercase"
                  v-for="item in subscribeData"
                  :key="item.id">
                    {{ item.name[locale] }}
                  </th>
                </tr>
              </thead>
                <tbody
                class="tariff-desc__table-tbody-sm"
                v-for="(item, i) in tariffConfig" :key="'_' + i">
                  <tr>
                    <td colspan="3">
                      {{ item.title[locale] }}
                      <span
                        v-if="item.hint"
                        class="tariff-desc__popover bg-dark"
                        v-c-popover="{
                          header: item.title[locale],
                          content: item.hint[locale],
                          placement: 'right',
                        }"
                      >
                        ?
                      </span>
                    </td>
                  </tr>
                    <tr>
                      <td
                      :style="{width: '18.3%'}"
                      class="text-center">
                      <template v-if="isBoolean(item.value.basic)">
                        <div
                        v-if="item.value.basic"
                        class="tariff-desc__check">
                          <CIcon
                            name="check"
                          />
                        </div>
                        <span v-else>
                          -
                        </span>
                      </template>
                      <template v-else>
                        {{ item.value.basic }}
                      </template>
                    </td>
                    <td
                    class="text-center"
                    :style="{width: '18.3%'}">
                      <template v-if="isBoolean(item.value.pro)">
                        <div
                        v-if="item.value.pro"
                        class="tariff-desc__check">
                          <CIcon
                            name="check"
                          />
                        </div>
                        <span v-else>
                          -
                        </span>
                      </template>
                      <template v-else>
                        {{ item.value.pro }}
                      </template>
                    </td>
                    <td
                    class="text-center"
                    :style="{width: '18.3%'}">
                      <template v-if="isBoolean(item.value.pro_alerts)">
                        <div
                        v-if="item.value.pro_alerts"
                        class="tariff-desc__check">
                          <CIcon
                            name="check"
                          />
                        </div>
                        <span v-else>
                          -
                        </span>
                      </template>
                      <template v-else>
                        {{ item.value.pro_alerts }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              <tbody class="tariff-desc__table-tbody-lg">
                <tr v-for="(item, i) in tariffConfig" :key="'_' + i">
                  <td
                  class="tariff-desc__table-template-lg"
                  :style="{width: '45%'}">
                    {{ item.title[locale] }}
                    <span
                      v-if="item.hint"
                      class="tariff-desc__popover bg-dark"
                      v-c-popover="{
                        header: item.title[locale],
                        content: item.hint[locale],
                        placement: 'right',
                      }"
                    >
                      ?
                    </span>
                  </td>
                  <td
                  :style="{width: '18.3%'}"
                  class="text-center">
                    <template v-if="isBoolean(item.value.basic)">
                      <div
                      v-if="item.value.basic"
                      class="tariff-desc__check">
                        <CIcon
                          name="check"
                        />
                      </div>
                      <span v-else>
                        -
                      </span>
                    </template>
                    <template v-else>
                      {{ item.value.basic }}
                    </template>
                  </td>
                  <td
                  class="text-center"
                  :style="{width: '18.3%'}">
                    <template v-if="isBoolean(item.value.pro)">
                      <div
                      v-if="item.value.pro"
                      class="tariff-desc__check">
                        <CIcon
                          name="check"
                        />
                      </div>
                      <span v-else>
                        -
                      </span>
                    </template>
                    <template v-else>
                      {{ item.value.pro }}
                    </template>
                  </td>
                  <td
                  class="text-center"
                  :style="{width: '18.3%'}">
                    <template v-if="isBoolean(item.value.pro_alerts)">
                      <div
                      v-if="item.value.pro_alerts"
                      class="tariff-desc__check">
                        <CIcon
                          name="check"
                        />
                      </div>
                      <span v-else>
                        -
                      </span>
                    </template>
                    <template v-else>
                      {{ item.value.pro_alerts }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!--    <CModal-->
    <!--      :title="$t('lk.subscribe.modal.tariffChange')"-->
    <!--      centered-->
    <!--      :show="showConfirm"-->
    <!--      @update:show="showConfirm = $event"-->
    <!--    >-->
    <!--      <p>{{ $t('lk.subscribe.modal.daysLeft') }}: {{ explanationData.days_left || '—' }}</p>-->
    <!--      <p>{{ $t('lk.subscribe.modal.amountLeft') }}: {{ explanationData.amount_left || '—' }}$</p>-->
    <!--      <p>{{ $t('lk.subscribe.modal.message') }}</p>-->
    <!--      <template v-slot:footer>-->
    <!--        <div class="w-100">-->
    <!--          <CLoadingButtonCustom-->
    <!--            color="primary"-->
    <!--            class="mr-2"-->
    <!--            @click.native="payTariff(emittedTariff, true)"-->
    <!--          >-->
    <!--            {{ $t('lk.subscribe.modal.switch') }}-->
    <!--          </CLoadingButtonCustom>-->
    <!--          <CButton-->
    <!--            color="dark"-->
    <!--            @click="showConfirm = false"-->
    <!--          >-->
    <!--            {{ $t('lk.subscribe.modal.cancel') }}-->
    <!--          </CButton>-->
    <!--        </div>-->
    <!--      </template>-->
    <!--    </CModal>-->

    <CModal
      :title="$t('lk.subscribe.modal.tariffChange')"
      centered
      :show="showPopupError"
      @update:show="showPopupError = $event"
    >
      <template v-if="paymentUrl">
        <p>{{ $t("lk.subscribe.modal.pageNoOpen") }}</p>
        <CLink
          class="text-primary"
          target="_blank"
          :href="paymentUrl"
          @click="showPopupError = false"
        >
          {{ paymentUrl }}
        </CLink>
      </template>
      <template v-else>
        <p>{{ errorMessage }}</p>
      </template>
      <template v-slot:footer>
        <div class="w-100">
          <CButton color="dark" @click="showPopupError = false">
            {{ $t("lk.subscribe.modal.cancel") }}
          </CButton>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import { tariffConfig } from '@/assets/js/tariffConfig'
import TariffCard from "@/components/subscribe/TariffCard"
import axios from "axios"
import moment from "moment"
import ChangeTariff from "@/components/subscribe/ChangeTariff.vue"
import PurchaseSuccessfulModal from "@/components/training/PurchaseSuccessfulModal"
import ListTraining from "@/components/training/ListTraining"
import {mapGetters, mapState} from 'vuex'
import Countdown from "@/pages/lk/Countdown.vue"
import BlackFridayModal from '@/components/black-friday/BlackFridayModal.vue'

export default {
  name: "Subscribe",
  components: {
    Countdown,
    ChangeTariff,
    PurchaseSuccessfulModal,
    TariffCard,
    ListTraining,
    BlackFridayModal
  },
  data() {
    return {
      showBlackFridayModal: Object.prototype.hasOwnProperty.call(this.$route.query, 'blackfriday'),
      tariffConfig,
      isYear: true,
      subscribeData: [],
      activeMonth: 1,
      waitPay: false,
      // payId: null,
      timer: null,
      timerIntervalDefault: 10000, // 10 sec,
      // waitText: '',
      targetTariff: {
        tariff_id: null,
        tariff_label: null,
        month_count: null,
        color: null,
        isTraining: false,
        isIndependent: false,
        curator: null,
      },
      showCongratulate: false,
      showExplanation: false,
      // showConfirm: false,
      // emittedTariff: null,
      showPopupError: false,
      paymentUrl: "",
      loadingTariffs: false,
      loadingEduTariffsAccess: false,
      errorMessage: "",
      showTab: 0,
    };
  },
  mounted() {
    this.$store.dispatch('user/getCurrent')
      .then(({status}) => {
        this.$root.$emit('dropdownMenuUpdate')
      })
    if(this.$route.query.study) this.setTabs(1)
  },

  computed: {
    ...mapState({
      userData: state => state.user.userData
    }),
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    userTariffActive() {
      return this.$store.state.user.userData.tariffIsActive;
    },
    getEvents() {
      return this.$store.state.user.userData?.events_users?.[0];
    },
    userTariffId() {
      if (!this.userTariffActive) return "";
      return this.$store.state.user.userData.tariff.id;
    },
    getToDate() {
      const date = this.$store.state.user.userData.tariffExpired;
      if (!date) return "";

      const dateFormat = new moment(date);
      if (!dateFormat.isValid()) return "";

      return ` ${this.$t("lk.subscribe.toDate")} ${dateFormat.format(
        "DD.MM.YY"
      )}`;
    },
    tariffFinishDate(){
      return new moment(this.$store.state.user.userData.tariffExpired).format("DD.MM.YY")
    },
    // showPayLoader() {
    //   return this.waitPay || this.payTariffRequest
    // }
    locale() {
      return this.$root.$i18n.locale;
    },
    blackFriday() {
      return this.userData.promotions.find(el => el.name === 'blackfriday2023')
    },
    blackFridayDiff() {
      const now = moment().utc()
      return this.blackFriday ? this.blackFriday.endAt.diff(now) : 0
    }
  },
  watch: {
    "$route.query.study"(val) {
      this.setTabs(val)
    }
  },
  created() {
    // this.subscribeData = staticSubscribeCards
    this.getTariffs();
    // this.disabledTrainingCardPay()

    if (!this.userTariffActive && !this.$store.state.user.userData.tariff.id) {
      this.activeMonth = 12;
    }
  },
  destroyed() {
    // if (this.timer) {
    //   clearTimeout(this.timer)
    // }
  },
  methods: {
    isBoolean(value){
      return typeof value === 'boolean'
    },
    toggleShowCongratulate(){
      if(this.targetTariff.tariff_id === 'standard_edu') this.showCongratulate = true
    },
    updateShow(showStatus) {
      this.showExplanation = showStatus;
    },
    setTabs(id){
      if(id) {
        this.showTab = 1
      } else {
        this.showTab = 0
      }
    },
    nextTab(id) {
      this.showTab = id
      this.$router.push({name: 'lk-subscribe', query: id ? {study: id} : null})
    },
    handleError(error) {
      this.showPopupError = true;
      if (error.type === "block") {
        this.paymentUrl = error.message;
      } else {
        this.errorMessage = error.message;
      }
    },
    prePay(obj = {}) {
      this.targetTariff.month_count = obj.month_count;
      this.targetTariff.tariff_id = obj.tariff_id;
      this.targetTariff.color = obj.color;
      this.targetTariff.title = obj.title;
      this.targetTariff.isTraining = obj.isTraining;
      this.targetTariff.isIndependent = obj.isIndependent || false;
      this.targetTariff.tariff_label = obj.title && obj.title[this.locale] || null;
      if(this.targetTariff.tariff_label){
        this.targetTariff.tariff_label = this.targetTariff.tariff_label.toUpperCase()
      }
      this.targetTariff.curator = obj.curator;
      this.updateShow(true);
    },
    prePayFromBlackfriday (obj) {
      console.log(obj)
      this.targetTariff = {
        tariff_id: obj.tariffId,
        month_count: obj.tariffMonths,
        title: obj.title,
        tariff_label: obj.title,
      }
      this.updateShow(true);
    },
    disabledPay(tariffId) {
      // if (this.payTariffRequest) return true

      // if (this.waitPay) return true
      if (!this.userTariffId) return false;

      if (this.userTariffId !== "trial_delta_screener") {
        if (this.userTariffId === "delta") {

          return false;
        }
        if (this.userTariffId === "screener") {
          return tariffId === "delta";
        }
        if (this.userTariffId === "basic") {
          return tariffId === "screener" || tariffId === "delta";
        }
        if (this.userTariffId === "pro") {
          return (
            tariffId === "screener" ||
            tariffId === "delta" ||
            tariffId === "basic"
          );
        }
      } else {
        return false;
      }
    },
    getTariffs() {
      this.loadingTariffs = true;
      return axios
        .get("/api/v2/tariffs")
        .then(({ data, status }) => {
          if (status < 300) {
            this.subscribeData = data.data;
            if(data?.promotions){
              this.userData?.setPromotions(data.promotions || [], data.promoSummary || {})
            }
          }
        })
        .finally(() => {
          this.loadingTariffs = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @include media-breakpoint-down(xs) {
    justify-content: flex-start;

    .title {
      display: none;
    }
    .time-range-center {
      flex-direction: column;
    }
    /deep/ .title-custom {
      align-self: flex-start;
      margin-bottom: 0.25rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .title {
      font-size: 1.25rem;
    }
    .time-range-center {
      margin-left: 1.5rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .title {
      font-size: 2.25rem;
    }

    .time-range-center {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-50%, -50%);

      .title-custom {
        position: absolute;
        top: 50%;
        left: -90px;
        transform: translateY(-50%);
      }
    }
  }
}

.subtitle {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.subscribe {
  &__tabs {
    display: flex;
    gap: 20px;

    @include media-breakpoint-up(md) {
      gap: 32px;
    }

    &__item {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      padding-bottom: 8px;
      cursor: pointer;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 29px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 35px;
        line-height: 42px;
      }
    }
  }
}

._active {
  color: var(--white);
  border-bottom: solid var(--primary) 2px;
}

.subcards {
  .crow {
    gap: 2rem;
  }

  @include media-breakpoint-down(xl) {
    .crow {
    }
  }

  @include media-breakpoint-down(xs) {
    .crow {
      gap: 1.25rem;
    }
  }
}
.tarff-card-wrapper{
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  margin-bottom: 32px;
  @include media-breakpoint-up(md){
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-rows: auto;
    margin-bottom: 42px;
  }
  @include media-breakpoint-up(xxl){
    // grid-template-columns: repeat(4, 1fr);
    grid-template-rows: initial;
    gap: 13px;
    margin-bottom: 46px;
  }
  &__trial{
    margin-top: 15px;
    @include media-breakpoint-up(md){
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      margin-top: 0;
    }
  }

  &__controls{
    position: relative;
    background-color: transparent;
    // border: 1px solid var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // padding-top: 20px;
    margin-bottom: 20px;
    height: fit-content;
    @include media-breakpoint-up(md){
      flex-direction: row;
      grid-column: 1 / -1;
      grid-row: 1 / -1;
      // padding-top: 22px;
      // padding-bottom: 49px;
    }
    @include media-breakpoint-up(xxl){
      /* grid-column: initial; */
    }
  }
  &__controls-switch{
    flex-shrink: 0;
    /deep/ .c-switch-slider::before{
      background: var(--primary);
    }
    /deep/ .c-switch-input:checked + .c-switch-slider::before{
      background: white;
    }
  }
  &__discount-pin{
    border: 1px solid var(--primary);
    border-radius: 50px;
    padding: 4px 6px;
    color: var(--primary);
    margin-left: 10px;
    // position: absolute;
    // right: -5px;
    // bottom: -31px;
  }

  &__black-friday {
    text-shadow: 0px 0px 8.611112594604492px #D60000, 0px 0px 10.333335876464844px #F10000;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 32px;
    border: 1px solid;
    box-shadow: 0px 0px 3px 0px #D60000, 0px 0px 6px 0px #FFF, 0px 0px 3px 0px #F10000, 0px 0px 6px 0px #F10000;
    display: inline-block;
    padding: 6px 12px;
    margin-left: 10px;
    text-align: center;
  }
}
.tariff-desc{
  /deep/ .arrow{
    display: none;
  }
  &__title{
    font-weight: 600;
    font-size: 22px;
    line-height: 116%;
    letter-spacing: -0.02em;
    margin-bottom: 18px;
    @include media-breakpoint-up(md){
      font-size: 28px;
    }
    @include media-breakpoint-up(xxl){
      font-size: 32px;
    }
  }
  &__table-wrapper{
    // overflow: auto;
    // max-height: 400px;
  }
  &__table{
    position: relative;

    thead tr th {
      background: var(--second-card-bg);
      position: sticky;
      top: -21px;
      z-index: 1;
      @include media-breakpoint-up(sm){
        top: -30px;
      }
      @include media-breakpoint-up(md){
        top: -29px;
      }
      @include media-breakpoint-up(xl){
        top: -36px;
      }
    }
  }
  &__check{
    width: 17.5px;
    height: 17.5px;
    border-radius: 50%;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    & svg{
      width: 14px !important;
      height: 14px !important;
      color: white;
    }
  }
  &__table-template-lg{
    display: none;
    @include media-breakpoint-up(md){
      display: table-cell;
    }
  }
  &__popover{
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    font-size: 0.75rem;
    margin-left: 4px;
  }
  &__table-template-sm{
    @include media-breakpoint-up(md){
      display: none;
    }
  }
  &__table-tbody-lg{
    display: none;
    @include media-breakpoint-up(md){
      display: table-row-group;
    }
  }
  &__table-tbody-sm{
    @include media-breakpoint-up(md){
      display: none;
    }
  }
}
.tumbler-wrapper{
  height: fit-content;
  & label{
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -1px;
    @include media-breakpoint-up(md){
      font-size: 20px;
      line-height: 24px;
    }
  }
}
</style>
