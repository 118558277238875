<template>
  <div
    :class="[
      'tariff-card',
      { '--active': active && !isGuest },
      `_${tariff.id}`,
      { '_black-friday': !!blackFriday && !!currentPriceObject.oldPrice },
    ]"
  >
    <div class="tariff-card__section">
      <div
        class="tariff-card__header"
        :class="{ 'align-items-center flex-wrap': activeTariffId === 'pro_alerts' }"
      >
        <span class="tariff-card__title">
          {{ tariff.name[locale] }}
        </span>
        <span
          v-if="active && tariff.id !== 'basic'"
          class="tariff-card__subtitle"
        >
          {{ $t('general.Active') }} {{ toDate }}
        </span>
        <TrialGroup
          v-if="isBonusVisible"
          :btnColor="activeTariffId === 'pro_alerts' ? 'white' : 'primary'"
          :btnText="
            activeTariffId === 'pro_alerts'
              ? $t('lk.subscribe.trial.trialProAlertsPeriod').toString()
              : $t('lk.subscribe.trial.trialPeriod').toString()
          "
          :class="{ 'ctrial-group__active': activeTariffId === 'pro_alerts' }"
        />
      </div>
      <span class="tariff-card__subtitle">
        {{ tariff.description[locale] }}
      </span>
    </div>
    <div class="tariff-card__section --second">
      <span
        class="tariff-card__free"
        v-if="tariff.id === 'basic'"
      >
        {{ $t('general.free') }}
      </span>
      <div v-else>
        <span class="tariff-card__price">
          {{ currentPriceObject.price }}
        </span>
        <span class="tariff-card__price-currency"> $ </span>
        <span
          v-if="currentPriceObject.oldPrice"
          class="tariff-card__price-old-wrap"
        >
          <span class="tariff-card__price-strike"> ${{ currentPriceObject.oldPrice }} </span>
          <span
            v-if="blackFriday"
            class="tariff-card__price-percentage"
          >
            {{ tariff.id === 'pro_alerts' ? '-20%' : '-25%' }}
          </span>
        </span>
      </div>
      <CButton
        block
        :color="tariff.id === 'basic' && !isGuest ? 'secondary' : 'primary'"
        class="tariff-card__buy-button"
        :disabled="disableButton"
        @click="clickEvent"
      >
        {{ buttonTitle }}
      </CButton>
    </div>
    <SubscribeTgModal
      :show="showTgSubscribeModal"
      @close="showTgSubscribeModal = false"
    />
  </div>
</template>

<script>
import TrialGroup from '@/components/subscribe/TrialGroup'
import axios from 'axios'
import SubscribeTgModal from '@/components/subscribe/SubscribeTgModal'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'TariffCard',
  components: { SubscribeTgModal, TrialGroup },
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
    isYear: {
      type: Boolean,
      default: false,
    },
    activeTariffId: {
      type: String,
      required: true,
    },
    activeTariff: {
      type: Boolean,
      required: true,
    },
    toDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showTgSubscribeModal: false,
    }
  },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
    ...mapState({
      userData: (state) => state.user.userData,
    }),
    toolTipContent() {
      if (this.tariff.id === 'basic' && this.activeTariff && this.activeTariffId !== 'basic') {
        return { content: this.$t('lk.subscribe.basicTooltip') }
      }
      return { content: false }
    },
    disableButton() {
      if(this.isGuest){
        return false
      }
      if (this.tariff.id === 'basic' && this.active) return true
    },
    buttonTitle() {
      if(this.isGuest){
        return this.$t('formLogin.signIn')
      }
      if (this.tariff.id === 'basic' && this.active) return this.$t('general.active')
      if (this.active) return this.$t('lk.subscribe.prolong')
      return this.$t('lk.subscribe.pay')
    },
    locale() {
      return this.$root.$i18n.locale
    },
    currentPriceObject() {
      return this.isYear
        ? this.tariff.variants.find((item) => item.monthsCount == 12)
        : this.tariff.variants.find((item) => item.monthsCount == 1)
    },
    active() {
      if (this.tariff.id === 'mentorship_edu') return this.hasMentor
      if (this.activeTariff) {
        return this.activeTariffId === this.tariff.id
      }
      return false
    },
    isBonusVisible() {
      // TTT-899
      return this.tariff.id === 'pro_alerts' && this.activeTariffId !== 'pro_alerts'
    },
    blackFriday() {
      return this.userData.promotions.find((el) => el.name === 'blackfriday2023')
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      toggleShowLoginModal: 'user/toggleShowLoginModal',
    }),
    async clickEvent() {
      if(this.isGuest){
        this.toggleShowLoginModal({show: true})
        return
      }
      if (this.disableButton || this.toolTipContent.content) {
        if (this.toolTipContent.content) {
          this.$toastr.error(this.$t(this.toolTipContent.content));
        }
        return
      }
      if (this.tariff.id === 'basic') {
        let isValid
        const res = await axios.get('api/v1/users/check-telegram-subscribe')
        if (res.status < 300) {
          if (res.data.error) {
            isValid = false
          }
          isValid = res?.data?.tg_group_subscribe
          if (!res?.data?.tg_group_subscribe) this.showTgSubscribeModal = true
        }
        if (isValid) {
          axios
            .post('/api/v2/tariffs/buy', {
              tariff_id: 'basic',
              month_count: 1,
              balance: 1,
            })
            .then(({ data }) => {
              const { tariff_changed } = data
              if (tariff_changed) {
                this.$toastr.success(this.$t('lk.subscribe.changeTariffSuccess'))
                this.$store.dispatch('user/getCurrent').then(({ status }) => {
                  if (status < 300) {
                    this.$toastr.success(this.$t('lk.subscribe.reloadedStatus'))
                  }
                })
                this.$emit('tariffChanged')
              }
            })
            .catch((e) => {
              console.error('error pay tariff', e)
            })
        }
      } else {
        this.$emit('pay', {
          tariff_id: this.tariff.id,
          title: this.tariff.name,
          month_count: this.isYear ? 12 : 1,
          color: this.tariff.color,
          isTraining: this.tariff.isTraining,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tariff-card {
  background-color: var(--second-card-bg);
  border-radius: 10px;
  padding: 22px 24px 24px 24px;
  min-height: 246px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    min-height: 280px;
  }
  @include media-breakpoint-up(xxl) {
    min-height: 300px;
  }
  &.--active {
    background-color: var(--primary);
    .tariff-card__buy-button {
      background-color: white;
      color: black;
    }
    .tariff-card__subtitle,
    .tariff-card__title,
    .tariff-card__price,
    .tariff-card__price-currency {
      color: white;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: baseline;
    @include media-breakpoint-up(xxl) {
      margin-bottom: 22px;
    }
    // .tariff-card__subtitle{
    //   opacity: 1;
    // }
  }
  &__section {
    display: flex;
    flex-direction: column;
    &.--second {
      margin-top: auto;
    }
  }
  &__title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    @include media-breakpoint-up(xxl) {
      font-size: 24px;
      line-height: 29px;
    }
  }
  &__subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    // opacity: 0.5;
    @include media-breakpoint-up(xxl) {
      font-size: 16px;
    }
  }
  &__free {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    @include media-breakpoint-up(xxl) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  &__price {
    font-weight: 600;
    font-size: 46px;
    line-height: 55px;
    letter-spacing: -0.02em;
  }
  &__price-currency {
    font-weight: 400;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.02em;
    margin-left: -7px;
  }
  &__price-strike {
    font-size: 1.15rem;
    position: relative;
    opacity: 0.3;
    display: inline-block;
    transform: translateY(-5px);
    height: fit-content;
    width: fit-content;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1px;
      transform: rotate(330deg);
      background: var(--white);
    }
  }
  &__buy-button {
    height: 44px;
    margin-top: 14px;
  }
  .ctrial-group__active {
    width: 100%;
    .tryBtn {
      margin-left: auto;
    }
  }

  &__price-old-wrap {
    display: inline-flex;
    height: 100%;
    vertical-align: bottom;
    flex-direction: column;
  }

  &__price-percentage {
    transform: translateX(5px);
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-shadow: 0px 0px 8.611112594604492px #d60000, 0px 0px 10.333335876464844px #f10000;
    border-radius: 50px;
    padding: 4px 8px;
    border: 1px solid;
    box-shadow: 0px 0px 3px 0px #d60000, 0px 0px 6px 0px #fff, 0px 0px 3px 0px #f10000,
      0px 0px 6px 0px #f10000;
    text-align: center;
  }

  &._pro._black-friday {
    box-shadow: 0px 0px 8.61111px 0px #d60000, 0px 0px 23.25px 0px rgba(255, 255, 255, 0.68),
      0px 0px 8.61111px 0px #fff, 0px 0px 13.9px 0px #d00032;
  }
}
</style>
