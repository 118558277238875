<template>
  <div class="black-friday-modal-tariff">
    <!-- Шапка карточки: -->
    <div class="black-friday-modal-tariff__header">
      <h3 class="black-friday-modal-tariff__title">{{ title }}</h3>
      <p class="black-friday-modal-tariff__discount">{{ discount }}</p>
    </div>

    <!-- Тело карточки: -->
    <div class="black-friday-modal-tariff__body">
      <p
        v-for="paragraph, paragraphIndex in descriptionParagraphs"
        :key="paragraphIndex"
      >
        {{ paragraph }}
      </p>
    </div>

    <!-- Футер карточки: -->
    <div class="black-friday-modal-tariff__footer">
      <CButton
        :class="'black-friday-modal-tariff__buy-button'"
        :color="'primary'"
        @click="$emit('change-tariff', $props)"
      >
        {{ $t('lk.subscribe.blackFriday.buyButton') }}
      </CButton>

      <!-- Цена: -->
      <div class="black-friday-modal-tariff__price">
        <span class="black-friday-modal-tariff__price-number _new">{{ newPrice }}</span>
        <span class="black-friday-modal-tariff__price-number _old">{{ oldPrice }}</span>
      </div>

      <!-- Бонус: -->
      <div class="black-friday-modal-tariff__bonus">
        {{ bonus }}
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@coreui/vue-pro';

export default {
  emits: [
    'change-tariff',
  ],
  props: {
    tariffId: String,
    tariffMonths: Number,
    title: String,
    discount: String,
    description: String,
    newPrice: String,
    oldPrice: String,
    bonus: String,
  },
  computed: {
    descriptionParagraphs () {
      return this.description.split(/\n/g)
    }
  },
  components: {
    CButton
  },
  data: () => ({}),
}
</script>

<style lang="scss">
@import "@/assets/scss/includes/dark-schema";

.black-friday-modal-tariff {
  padding: 18px 28px 20px 28px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);

  @media (max-width: 545px) {
    padding: 18px 16px 20px 16px;;
  }

  // Элементы:
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 545px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;

      // Дочерние:
      & > * + * {
        margin-bottom: 16px !important;
      }
    }
  }

  &__title {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    line-height: normal;

    @media (max-width: 545px) {
      font-size: 24px;
    }
  }

  &__discount {
    padding: 8px 16px;
    border-radius: 999px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    color: #24252F;
    background: #FFF;
  }

  &__body {
    margin-top: 25px;

    // Дочерние:
    & > * {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.3px;
      opacity: 0.7;
    }
    & > * + * {
      margin-top: 0.5lh;
    }
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    margin-top: 25px;

    @media (max-width: 545px) {
      flex-direction: column;
      align-items: center;
    }

    // Дочерние:
    & > * + * {
      margin-left: 16px;

      @media (max-width: 545px) {
        margin-left: 0;
      }
    }
  }

  &__buy-button {
    width: 198px;
    padding: 12px;
    font-size: 15px;

    @media (max-width: 545px) {
      width: 100%;
      margin-top: 16px;
      order: 3;
    }
  }

  &__price {
    display: flex;
    align-items: flex-end;

    @media (max-width: 545px) {
      order: 1;
    }

    // Дочерние:
    & > * + * {
      margin-left: 8px;
    }
  }

  &__price-number {
    font-weight: 400;
    line-height: normal;

    // Модификаторы:
    &._new {
      font-size: 32px;

      @media (max-width: 545px) {
        font-size: 24px;
      }
    }

    &._old {
      text-decoration-line: line-through;
      font-size: 19px;
      opacity: 0.5;
    }
  }

  &__bonus {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;

    @media (max-width: 545px) {
      order: 2;
    }
  }
}
</style>
