<template>
  <CModal
    centered
    :show="show"
    @update:show="$emit('update:show', $event)"
    class="addCuratorModal"
  >
    <template #header-wrapper>
      <div class="addCuratorModal__header">
        <span>{{$t('lk.subscribe.complite.title')}}</span>
        <div @click="close" class="addCuratorModal__header-close">×</div>
      </div>
    </template>
    <span v-html="$t('lk.subscribe.complite.content').replace(/(?:\r\n|\r|\n)/g, '<br>')"></span>
    <div class="addCuratorModal__btnBlock">
      <CButton class="shadow-none addCuratorModal__btn"
               color="primary"
               @click="close">
        OK
      </CButton>
    </div>

    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>

export default {
  name: 'AppointCurratorModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;
  max-height: calc(100vh - 300px);
  max-height: calc(100dvh - 300px);

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.addCuratorModal{

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    padding-top: 16px;
    font-size: 21px;
    font-weight: 500;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
      padding-top: 24px;
    }

    &-close {
      font-size: 46px;
      font-weight: 100;
      line-height: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $danger;
      }
    }
  }

  &__btn {
    width: 25%;
    height: 44px;
    margin-left: auto;
  }

  &__btnBlock {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
</style>
