<template>
  <CModal
   centered
   :show="show"
   @update:show="close"
   :title="`${$t('lk.permission.table.tariff')} BASIC`"
   class="subscribeTgModal"
   >
   <div class="subscribeTgModal__content">
      <p>
        {{ $t("lk.subscribe.tgModalTitle",{tariff: 'BASIC'}) }}
      </p>
      <div class="subscribeTgModal__list">
        <div class="subscribeTgModal__list-item">
          <!-- <span
          class="subscribeTgModal__list-item-dot"/> -->
          <CIcon
          name="cil-check"
          :class="[$store.getters['user/telegramVerify'] ? 'text-success' : '']" />
          <!-- <template v-if="$store.getters['user/telegramVerify']">
            <span>
              {{ $t("lk.account.verifiedTelegram") }}
            </span>
            <CIcon name="cil-check" class="text-success" />
          </template> -->
          <template>
            {{ $t("lk.subscribe.tgModalPoint1") }}
            <!-- <a
            :href="$store.state.user.userData.tgLink"
            target="_blank">
            <img
              src="/img/svg/media-links/telegram-cryptovizor.svg"
              :alt="$store.state.user.userData.tgLink"
              class="icon --link"
            >
            </a> -->
            <CButton
            v-if="!$store.getters['user/telegramVerify']"
            class="subscribeTgModal__list-item-button"
            color="primary"
            @click="verifyTg">
              {{ $t('general.verify') }}
            </CButton>
          </template>
        </div>
        <div class="subscribeTgModal__list-item">
          <CIcon
          name="cil-check"
          />
          <!-- <span class="subscribeTgModal__list-item-dot"/> -->
          {{ $t("lk.subscribe.tgModalPoint2") }}
          <!-- <a
          @click="close"
          href="https://t.me/cvizor"
          target="_blank"> -->
            <!-- <img
              src="/img/svg/media-links/telegram-cryptovizor.svg"
              alt="https://t.me/cvizor"
              class="icon"
            > -->
          <!-- </a> -->
          <CButton
          v-if="$store.getters['user/telegramVerify']"
          class="subscribeTgModal__list-item-button"
          color="primary"
          @click="subscribeTg">
            {{ $t('marketplace.subscribe') }}
          </CButton>
        </div>
      </div>
      <!-- <div class="subscribeTgModal__qr-wrapper">
        <img
          src="@/assets/img/cvizorqr.jpg"
          alt="https://t.me/cvizor"
        >
      </div> -->
    </div>
 </CModal>
</template>

<script>
import axios from "axios";
export default {
 name: "SubscribeTgModal",
 props: {
   show: {
     type: Boolean,
     default: false
   }
 },
 methods: {
  verifyTg(){
    window.open(this.$store.state.user.userData.tgLink, '_blank');

    this.checkTelegramVerify()
  },
  checkTelegramVerify() {
    const oldTimer = this.$store.state.user.telegramVerifyTimer
    if (oldTimer) {
      clearTimeout(oldTimer)
      this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', null)
    }

    const timer = setTimeout(async () => {
      await this.$store.dispatch('user/getCurrent')
      if (this.$store.getters['user/telegramVerify']) {
        this.$toastr.success(this.$t('lk.account.confirmAccountSuccessfully'))
      } else {
        this.checkTelegramVerify()
      }
    }, 7000)
    this.$store.commit('user/SET_TELEGRAM_VERIFY_TIMER', timer)
  },
  async subscribeTg(){
    await axios.post('api/v1/users/auto-basic')
    window.open('https://t.me/+oITBBhfgz4M3ZWJi', '_blank');

    this.checkTelegramSubscribe()
    this.close()
  },
  checkTelegramSubscribe() {
    const oldTimer = this.$store.state.user.telegramSubscribeTimer
    if (oldTimer) {
      clearTimeout(oldTimer)
      this.$store.commit('user/SET_TELEGRAM_SUBSCRIBE_TIMER', null)
    }

    const timer = setTimeout(async () => {
      const res = await axios.get('api/v1/users/check-telegram-subscribe')
      const isValid = res?.data?.tg_group_subscribe
      if (isValid) {
        this.$toastr.success(this.$t('lk.subscribe.changeTariffSuccess'))
        await this.$store.dispatch('user/getCurrent')
      } else {
        this.checkTelegramSubscribe()
      }
    }, 7000)
    this.$store.commit('user/SET_TELEGRAM_SUBSCRIBE_TIMER', timer)
  },
  close(){
    this.$emit('close')
  },
 }
}
</script>

<style lang="scss" scoped>
.subscribeTgModal{
  /deep/ .modal-footer{
    display: none;
  }
  &__list-item-button{
    margin-left: auto;
    width: 142px;
  }
  .icon {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    transition: 150ms ease-out;
    &.--link{
      @media (hover) {
        filter: grayscale(1);
      }

      &:hover {
        filter: none;
      }
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
  }
  &__list-item{
    display: flex;
    align-items: center;
  }
  &__list-item-dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary);
    margin-right: 6px;
  }
  &__close{
    position: absolute;
    cursor: pointer;
    user-select: none;
    right: 0;
    top: 0;
    &:hover {
      color: $danger;
    }
  }
 &__btn {
   width: 25%;
   height: 44px;
   margin-left: auto;
 }

 &__btnBlock {
   display: flex;
   flex-direction: column;
   gap: 20px;

   @include media-breakpoint-up(md) {
     flex-direction: row;
   }
 }
 &__content{
  position: relative;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & div{
    display: flex;
    gap: 5px;
    & svg{
      flex-shrink: 0;
    }
    & span{
      text-align: left;
    }
  }
 }
 &__qr-wrapper{
  justify-content: center;
  margin-top: 10px;
 }
}
</style>
