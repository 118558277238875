var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      class: "black-friday-modal",
      attrs: { show: _vm.value, centered: true, scrollable: true },
      scopedSlots: _vm._u([
        {
          key: "header-wrapper",
          fn: function () {
            return [_c("div", { staticClass: "_empty" })]
          },
          proxy: true,
        },
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("div", { staticClass: "_empty" })]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "button",
        {
          class: "black-friday-modal__close-button",
          on: { click: _vm.hideModal },
        },
        [_vm._v(" ✕ ")]
      ),
      _c("div", [
        _c("div", { staticClass: "black-friday-modal__header" }, [
          _c("h2", { staticClass: "black-friday-modal__title" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("lk.subscribe.blackFriday.title")) + " "
            ),
          ]),
          _c("p", { staticClass: "black-friday-modal__subtitle" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("lk.subscribe.blackFriday.subtitle")) + " "
            ),
          ]),
        ]),
        _c(
          "ul",
          { staticClass: "black-friday-modal__list" },
          _vm._l(_vm.tariffs, function (tariff, tariffKey) {
            return _c(
              "li",
              { key: tariffKey, class: "black-friday-modal__list-item" },
              [
                _c(
                  "BlackFridayModalTariff",
                  _vm._b(
                    {
                      on: {
                        "change-tariff": function ($event) {
                          return _vm.$emit("change-tariff", $event)
                        },
                      },
                    },
                    "BlackFridayModalTariff",
                    tariff,
                    false
                  )
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }