var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "tariff-card",
        { "--active": _vm.active && !_vm.isGuest },
        `_${_vm.tariff.id}`,
        {
          "_black-friday":
            !!_vm.blackFriday && !!_vm.currentPriceObject.oldPrice,
        },
      ],
    },
    [
      _c("div", { staticClass: "tariff-card__section" }, [
        _c(
          "div",
          {
            staticClass: "tariff-card__header",
            class: {
              "align-items-center flex-wrap":
                _vm.activeTariffId === "pro_alerts",
            },
          },
          [
            _c("span", { staticClass: "tariff-card__title" }, [
              _vm._v(" " + _vm._s(_vm.tariff.name[_vm.locale]) + " "),
            ]),
            _vm.active && _vm.tariff.id !== "basic"
              ? _c("span", { staticClass: "tariff-card__subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("general.Active")) +
                      " " +
                      _vm._s(_vm.toDate) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.isBonusVisible
              ? _c("TrialGroup", {
                  class: {
                    "ctrial-group__active": _vm.activeTariffId === "pro_alerts",
                  },
                  attrs: {
                    btnColor:
                      _vm.activeTariffId === "pro_alerts" ? "white" : "primary",
                    btnText:
                      _vm.activeTariffId === "pro_alerts"
                        ? _vm
                            .$t("lk.subscribe.trial.trialProAlertsPeriod")
                            .toString()
                        : _vm.$t("lk.subscribe.trial.trialPeriod").toString(),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("span", { staticClass: "tariff-card__subtitle" }, [
          _vm._v(" " + _vm._s(_vm.tariff.description[_vm.locale]) + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "tariff-card__section --second" },
        [
          _vm.tariff.id === "basic"
            ? _c("span", { staticClass: "tariff-card__free" }, [
                _vm._v(" " + _vm._s(_vm.$t("general.free")) + " "),
              ])
            : _c("div", [
                _c("span", { staticClass: "tariff-card__price" }, [
                  _vm._v(" " + _vm._s(_vm.currentPriceObject.price) + " "),
                ]),
                _c("span", { staticClass: "tariff-card__price-currency" }, [
                  _vm._v(" $ "),
                ]),
                _vm.currentPriceObject.oldPrice
                  ? _c("span", { staticClass: "tariff-card__price-old-wrap" }, [
                      _c("span", { staticClass: "tariff-card__price-strike" }, [
                        _vm._v(
                          " $" + _vm._s(_vm.currentPriceObject.oldPrice) + " "
                        ),
                      ]),
                      _vm.blackFriday
                        ? _c(
                            "span",
                            { staticClass: "tariff-card__price-percentage" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tariff.id === "pro_alerts"
                                      ? "-20%"
                                      : "-25%"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
          _c(
            "CButton",
            {
              staticClass: "tariff-card__buy-button",
              attrs: {
                block: "",
                color:
                  _vm.tariff.id === "basic" && !_vm.isGuest
                    ? "secondary"
                    : "primary",
                disabled: _vm.disableButton,
              },
              on: { click: _vm.clickEvent },
            },
            [_vm._v(" " + _vm._s(_vm.buttonTitle) + " ")]
          ),
        ],
        1
      ),
      _c("SubscribeTgModal", {
        attrs: { show: _vm.showTgSubscribeModal },
        on: {
          close: function ($event) {
            _vm.showTgSubscribeModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }