var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "black-friday-modal-tariff" }, [
    _c("div", { staticClass: "black-friday-modal-tariff__header" }, [
      _c("h3", { staticClass: "black-friday-modal-tariff__title" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c("p", { staticClass: "black-friday-modal-tariff__discount" }, [
        _vm._v(_vm._s(_vm.discount)),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "black-friday-modal-tariff__body" },
      _vm._l(_vm.descriptionParagraphs, function (paragraph, paragraphIndex) {
        return _c("p", { key: paragraphIndex }, [
          _vm._v(" " + _vm._s(paragraph) + " "),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "black-friday-modal-tariff__footer" },
      [
        _c(
          "CButton",
          {
            class: "black-friday-modal-tariff__buy-button",
            attrs: { color: "primary" },
            on: {
              click: function ($event) {
                return _vm.$emit("change-tariff", _vm.$props)
              },
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("lk.subscribe.blackFriday.buyButton")) + " "
            ),
          ]
        ),
        _c("div", { staticClass: "black-friday-modal-tariff__price" }, [
          _c(
            "span",
            { staticClass: "black-friday-modal-tariff__price-number _new" },
            [_vm._v(_vm._s(_vm.newPrice))]
          ),
          _c(
            "span",
            { staticClass: "black-friday-modal-tariff__price-number _old" },
            [_vm._v(_vm._s(_vm.oldPrice))]
          ),
        ]),
        _c("div", { staticClass: "black-friday-modal-tariff__bonus" }, [
          _vm._v(" " + _vm._s(_vm.bonus) + " "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }