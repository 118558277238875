export const tariffConfig = [
  {
    title:{
      en: 'Aggregated trading volumes and BTC delta',
      ru: 'Агрегированный объем и дельта BTC'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: 'Delta module displays realtime data from 7 leading exchanges for BTCUSD/USDT trading pairs.',
      ru: 'Модуль Delta отображает данные в режиме реального времени с 7 ведущих бирж для торговых пар BTCUSD/USDT.'
    }
  },
  {
    title:{
      en: 'Binance Spot screener of all the USDT pairs',
      ru: 'Скринер объемов + RSI всех USDT пар на Binance Spot'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    }
  },
  {
    title:{
      en: '5m, 15m, 30m, 1h, 2h, 4h, 12h, 1d Time frames included',
      ru: 'Рабочие таймфреймы 5m, 15m, 30m, 1h, 2h, 4h, 12h, 1d'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    }
  },
  {
    title:{
      en: 'Heatmap supports Volume, Volume change, Price Change, Volatility, RSI change',
      ru: 'Тепловая карта с фильтрами по Volume, Volume change, Price change, Волатильность, RSI change'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    },
  },
  {
    title:{
      en: 'Watchlists',
      ru: 'Вотчлисты'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Full compatibility with Tradingview's watchlist, one-click export and import",
      ru: 'Полная совместимость с вотчлистами Tradingview, загрузка и скачивание в один клик'
    }
  },
  {
    title:{
      en: 'Multichart + Trends tab',
      ru: 'Вкладка Multichart + Тренды'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "A grid of 1min charts and a trend table based on them",
      ru: 'Сетка из 1мин графиков и таблица трендов, основанная на них'
    }
  },
  {
    title:{
      en: 'The volatility factor',
      ru: 'Фактор волатильности'
    },
    value: {
      basic: true,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Standard deviation of price changes",
      ru: 'Cтандартное отклонение изменений цены'
    }
  },
  {
    title:{
      en: 'Tradingview charts on all screener modules',
      ru: 'Графики на всех страницах скринера'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Full functionality of the Tradingview standard library",
      ru: 'Полный функционал стандартной библиотеки Tradingview'
    }
  },
  {
    title:{
      en: 'Cumulative delta for all altcoins',
      ru: 'Кумулятивная дельта по всем альткоинам'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "The difference between market purchases and sales for supply and demand analysis",
      ru: 'Разница между рыночными покупками и продажами для анализа спроса и предложения'
    }
  },
  {
    title:{
      en: 'Sessions per user',
      ru: 'Сессий на юзера'
    },
    value: {
      basic: 1,
      pro: 3,
      pro_alerts: 3
    },
    hint:{
      en: "Simultaneous use on multiple devices: mobile, tablet, desktop",
      ru: 'Одновременное использование на нескольких устройствах: мобильный, планшет, десктоп'
    }
  },
  {
    title:{
      en: 'Running line',
      ru: 'Бегущая строка'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Feel like you're on the wall street",
      ru: 'Почувствуй себя на wall street'
    }
  },
  {
    title:{
      en: 'Custom MA in the screener',
      ru: 'Кастомные МА в скринере'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "The default setting is 50\\200, but you can set your own values",
      ru: 'По-умолчанию 50\\200, можно назначить свои значения'
    }
  },
  {
    title:{
      en: 'Telegram + Push notifications',
      ru: 'Оповещения в Telegram + Браузер'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
  },
  {
    title:{
      en: 'Withdrawal of referral bonuses in USDT',
      ru: 'Вывод реферальных бонусов в USDT'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Get a 10% bonus in USDT from the first payment of each referral",
      ru: 'Получай 10% бонус в USDT с первой оплаты каждого реферала'
    }
  },
  {
    title:{
      en: 'Terminal for Paper Trading in Telegram',
      ru: 'Терминал для Paper Trading в Telegram'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: "Make trades on a demo account, test your strategies and participate in the competition for the title of top of the month",
      ru: 'Совершай сделки на демо-счете, тестируй свои стратегии и участвуй в соревновании за звание топ месяца'
    }
  },
  {
    title:{
      en: 'Integration with trader\'s diary TMM',
      ru: 'Интеграция с дневником трейдера TMM'
    },
    value: {
      basic: false,
      pro: true,
      pro_alerts: true
    },
    hint:{
      en: 'Automatic filling description of trades with market data (MA, RSI, Volume, Delta) at the moment of opening and closing position.',
      ru: 'Автоматическое заполнение сделки рыночными данными (MA, RSI, Volume, Delta) на момент открытия и закрытия позиции.'
    }
  },
  {
    title:{
      en: 'Group alerts across the market',
      ru: 'Групповые оповещения по всему рынку'
    },
    value: {
      basic: false,
      pro: false,
      pro_alerts: 10
    },
    hint:{
      en: "Create alerts for 165+ coins at once, with complex conditions",
      ru: 'Создавайте оповещения сразу по 165+ монетам, с простыми или сложными условиями'
    }
  },
  {
    title:{
      en: 'Alerts marketplace',
      ru: 'Маркетплейс оповещений'
    },
    value: {
      basic: false,
      pro: false,
      pro_alerts: true
    },
    hint:{
      en: "Follow or create your own public signals and earn bonus tokens! ",
      ru: 'Подпишитесь или создавайте свои публичные сигналы и зарабатывайте бонусные токены! '
    }
  },
  {
    title:{
      en: 'Webhooks',
      ru: 'Вебхуки'
    },
    value: {
      basic: false,
      pro: false,
      pro_alerts: true
    },
    hint:{
      en: "Algorithmic trading with Cryptovizor via external terminals!",
      ru: 'Алгоритмическая торговля с помощью Cryptovizor через внешние терминалы!'
    }
  }
]
