<template>
  <vue-countdown
    v-if="time"
    :time="time"
    tag="div"
    class="countdown"
  >
    <template v-slot="props">
      <div v-if="title" class="title">{{ title }}</div>

      <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>

      <div class="time-wrap">
        <div class="time">
          <div class="time__num">
            {{ props.days }}
          </div>
          <div class="time__text">
            {{ $tc("pluralization.days", props.days) }}
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            :
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            {{ props.hours }}
          </div>
          <div class="time__text">
            {{ $tc("pluralization.hours", props.hours) }}
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            :
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            {{ props.minutes }}
          </div>
          <div class="time__text">
            {{ $tc("pluralization.minutes", props.minutes) }}
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            :
          </div>
        </div>

        <div class="time">
          <div class="time__num">
            {{ props.seconds }}
          </div>
          <div class="time__text">
            {{ $tc("pluralization.seconds", props.seconds) }}
          </div>
        </div>
      </div>
    </template>
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: "Countdown",
  components: {
    VueCountdown
  },
  props: {
    time: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>


<style scoped lang="scss">
.title {
  text-align: center;
  text-shadow: 0px 0px 10.333335876464844px #3686F6, 0px 0px 8.611112594604492px #D60000, 0px 0px 10.333335876464844px #F10000;
  font-size: 38px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 14px;
  z-index: 1;
}

.subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  opacity: .6;
  margin-bottom: 30px;
  z-index: 1;
}

.time {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;

  &__num {
    font-weight: 700;
    font-size: 28px;

    @include media-breakpoint-up(md) {
      font-size: 50px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 90px;
    }
  }

  &__text {
    font-weight: 400;
    opacity: .5;
    font-size: 15px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }
  }
}

.time-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 10px;

  @include media-breakpoint-up(md) {
    grid-gap: 20px;
  }

  @include media-breakpoint-up(lg) {
    grid-gap: 60px;
  }
}

.countdown {
  border-radius: 18px;
  padding: 30px 30px 85px;
  position: relative;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  &::before,
  &::after {
    content: '';
    opacity: 0.22;
    background: linear-gradient(180deg, #FFF 0%, #EC315E 53.12%);
    filter: blur(100px);
    border-radius: 50%;
    width: 390px;
    height: 390px;
    position: absolute;
    pointer-events: none;
  }

  &::before {
    left: -50px;
    bottom: -105px;
  }

  &::after {
    right: 50px;
    top: -50px;
  }
}
</style>
