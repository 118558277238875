var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.time
    ? _c("vue-countdown", {
        staticClass: "countdown",
        attrs: { time: _vm.time, tag: "div" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (props) {
                return [
                  _vm.title
                    ? _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ])
                    : _vm._e(),
                  _vm.subtitle
                    ? _c("div", { staticClass: "subtitle" }, [
                        _vm._v(_vm._s(_vm.subtitle)),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "time-wrap" }, [
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [
                        _vm._v(" " + _vm._s(props.days) + " "),
                      ]),
                      _c("div", { staticClass: "time__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$tc("pluralization.days", props.days)) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [_vm._v(" : ")]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [
                        _vm._v(" " + _vm._s(props.hours) + " "),
                      ]),
                      _c("div", { staticClass: "time__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc("pluralization.hours", props.hours)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [_vm._v(" : ")]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [
                        _vm._v(" " + _vm._s(props.minutes) + " "),
                      ]),
                      _c("div", { staticClass: "time__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc("pluralization.minutes", props.minutes)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [_vm._v(" : ")]),
                    ]),
                    _c("div", { staticClass: "time" }, [
                      _c("div", { staticClass: "time__num" }, [
                        _vm._v(" " + _vm._s(props.seconds) + " "),
                      ]),
                      _c("div", { staticClass: "time__text" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc("pluralization.seconds", props.seconds)
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              },
            },
          ],
          null,
          false,
          3435911192
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }