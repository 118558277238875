<template>
  <CModal
    :class="'black-friday-modal'"
    :show="value"
    :centered="true"
    :scrollable="true"
  >
    <!-- Кнопка закрытия окна: -->
    <button
      :class="'black-friday-modal__close-button'"
      @click="hideModal"
    >
      ✕
    </button>

    <!-- Скрытие шапки: -->
    <template #header-wrapper>
      <div class="_empty"></div>
    </template>

    <!-- Тело модального окна: -->
    <div>
      <div class="black-friday-modal__header">
        <h2 class="black-friday-modal__title">
          {{ $t('lk.subscribe.blackFriday.title') }}
        </h2>
        <p class="black-friday-modal__subtitle">
          {{ $t('lk.subscribe.blackFriday.subtitle') }}
        </p>
      </div>

      <!-- Список тарифов: -->
      <ul class="black-friday-modal__list">
        <li
          v-for="tariff, tariffKey in tariffs"
          :class="'black-friday-modal__list-item'"
          :key="tariffKey"
        >
          <BlackFridayModalTariff
            v-bind="tariff"
            @change-tariff="$emit('change-tariff', $event)"
          />
        </li>
      </ul>
    </div>

    <!-- Скрытия футера: -->
    <template #footer-wrapper>
      <div class="_empty"></div>
    </template>
  </CModal>
</template>

<script>
// Компоненты:
import { CModal } from '@coreui/vue-pro';
import BlackFridayModalTariff from './BlackFridayModalTariff.vue';

export default {
  emits: [
    'input'
  ],
  props: {
    value: Boolean,
  },
  data () {
    return {
      tariffs: [
        {
          tariffId: 'pro_alerts',
          tariffMonths: 18,
          title: this.$t('lk.subscribe.blackFriday.tariffs.proAlerts.title'),
          discount: this.$t('lk.subscribe.blackFriday.tariffs.proAlerts.discount'),
          description: this.$t('lk.subscribe.blackFriday.tariffs.proAlerts.description'),
          newPrice: '$399',
          oldPrice: '$499',
          bonus: this.$t('lk.subscribe.blackFriday.tariffs.proAlerts.bonus'),
        },
        {
          tariffId: 'pro',
          tariffMonths: 18,
          title: this.$t('lk.subscribe.blackFriday.tariffs.pro.title'),
          discount: this.$t('lk.subscribe.blackFriday.tariffs.pro.discount'),
          description: this.$t('lk.subscribe.blackFriday.tariffs.pro.description'),
          newPrice: '$299',
          oldPrice: '$399',
          bonus: this.$t('lk.subscribe.blackFriday.tariffs.pro.bonus'),
        },
      ],
    };
  },
  methods: {
    showModal () {
      this.$emit('input', true);
    },
    hideModal () {
      this.$emit('input', false);
    },
  },
  components: {
    CModal,
    BlackFridayModalTariff,
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/includes/dark-schema";

.black-friday-modal {
  color: #fff;

  // Дочерние:
  & .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }

  & .modal-content {
    width: 100%;
    max-width: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('~@/assets/img/black-friday-modal-bg.png');
  }

  & .modal-body {
    padding: 30px;

    @media (max-width: 545px) {
      padding: 30px 16px;
    }
  }

  // Элементы:
  &__close-button {
    position: absolute;
    inset: 0 0 auto auto;
    padding: 16px;
    margin: 0;
    line-height: 12px;
    border: none;
    background: none;
  }

  &__title {
    margin: 0;
    font-size: 40px;
    font-weight: 900;
    line-height: normal;
    text-align: center;

    @media (max-width: 545px) {
      font-size: 32px;
    }
  }

  &__subtitle {
    margin: 8px 0 0 0;
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    color: #2193b7;

    @media (max-width: 545px) {
      font-size: 16px;
    }
  }

  &__list {
    padding: 0;
    margin-top: 16px;
    list-style: none;
  }

  &__list-item {
    & + & {
      margin-top: 9px;
    }
  }
}
</style>
