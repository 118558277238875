var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "subscribeTgModal",
      attrs: {
        centered: "",
        show: _vm.show,
        title: `${_vm.$t("lk.permission.table.tariff")} BASIC`,
      },
      on: { "update:show": _vm.close },
    },
    [
      _c("div", { staticClass: "subscribeTgModal__content" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm.$t("lk.subscribe.tgModalTitle", { tariff: "BASIC" })) +
              " "
          ),
        ]),
        _c("div", { staticClass: "subscribeTgModal__list" }, [
          _c(
            "div",
            { staticClass: "subscribeTgModal__list-item" },
            [
              _c("CIcon", {
                class: [
                  _vm.$store.getters["user/telegramVerify"]
                    ? "text-success"
                    : "",
                ],
                attrs: { name: "cil-check" },
              }),
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint1")) + " "
                ),
                !_vm.$store.getters["user/telegramVerify"]
                  ? _c(
                      "CButton",
                      {
                        staticClass: "subscribeTgModal__list-item-button",
                        attrs: { color: "primary" },
                        on: { click: _vm.verifyTg },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("general.verify")) + " ")]
                    )
                  : _vm._e(),
              ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "subscribeTgModal__list-item" },
            [
              _c("CIcon", { attrs: { name: "cil-check" } }),
              _vm._v(" " + _vm._s(_vm.$t("lk.subscribe.tgModalPoint2")) + " "),
              _vm.$store.getters["user/telegramVerify"]
                ? _c(
                    "CButton",
                    {
                      staticClass: "subscribeTgModal__list-item-button",
                      attrs: { color: "primary" },
                      on: { click: _vm.subscribeTg },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("marketplace.subscribe")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }