var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "addCuratorModal",
      attrs: { centered: "", show: _vm.show },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("update:show", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header-wrapper",
          fn: function () {
            return [
              _c("div", { staticClass: "addCuratorModal__header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lk.subscribe.complite.title"))),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "addCuratorModal__header-close",
                    on: { click: _vm.close },
                  },
                  [_vm._v("×")]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "footer-wrapper",
          fn: function () {
            return [_c("span")]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("span", {
        domProps: {
          innerHTML: _vm._s(
            _vm
              .$t("lk.subscribe.complite.content")
              .replace(/(?:\r\n|\r|\n)/g, "<br>")
          ),
        },
      }),
      _c(
        "div",
        { staticClass: "addCuratorModal__btnBlock" },
        [
          _c(
            "CButton",
            {
              staticClass: "shadow-none addCuratorModal__btn",
              attrs: { color: "primary" },
              on: { click: _vm.close },
            },
            [_vm._v(" OK ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }